<template>
    <div id="apipe-cloud-course-page">
      <top-nav-new />
      <div class="apipe-cloud-course-container-madeSolution">
        <div class="pc-banner d-none d-lg-block">
          <div class="container" style="text-align: left">
            <h1 style="margin-bottom: 15px; font-size: 42px; font-weight: 700">
                优制云智慧园区IBMS
            </h1>
            <p class="tit" style="line-height:2rem;">
                    优制云工业互联网平台推出的智慧园区IBMS系统，是专为园区企业设计的专业级管理平台。本<br>系统深度聚焦并解决园区管理面临的信息孤岛、效率低下等核心痛点，通过集成智能监控、能效<br>优化、安全预警及综合服务调度等功能模块，实现园区资源的整合与智能化运营。优制云<br>IBMS以数据为驱动，提供可视化决策支持，强化安全管理，降低运营成本，提升服务响应速度<br>与质量，为园区企业的可持续发展奠定坚实基石。
            </p>
          </div>
          <MessageInfo />
        </div>
  
        <div class="mobile-banner d-block d-lg-none">
          <div class="container" style="text-align: left">
            <div class="title">优制云智慧园区IBMS</div>
            <div class="titwrap">
              <p class="tit">
                通过集成可视化监控、能源监控、环境监测、设施维护、安全巡检等智能化功能，为企业园区提供一个安全、高效、节能的运营环境。
              </p>
            </div>

          </div>
        </div>
  
        <!-- 行业痛点 -->
        <div class="pc-industry d-none d-lg-block">
          <div class="pro-tit">行业痛点</div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/hangye1.png" alt="" />
                  <h6>设施管理困难</h6>
                  <div class="des">
                    <div>设施管理依赖于人工巡检，效率</div>
                    <div>低下</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/hangye2.png" alt="" />
                  <h6>能源消耗高</h6>
                  <div class="des">
                    <div>能源使用缺乏有效监控，导致能</div>
                    <div>源浪费</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/hangye3.png" alt="" />
                  <h6> 信息服务不便捷</h6>
                  <div class="des">
                    <div>园区内信息发布不便捷，影响工</div>
                    <div>作效率</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/hangye4.png" alt="" />
                  <h6>空间利用率低</h6>
                  <div class="des">
                    <div>园区空间利用率不高，资源配置</div>
                    <div>不合理</div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
        <div class="mobile-industry d-block d-lg-none">
          <div class="pro-tit">行业痛点</div>
          <div class="container text-center">
            <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye1.png" alt="" />
                  <h6>设施管理困难</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye2.png" alt="" />
                  <h6>能源消耗高</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye3.png" alt="" />
                  <h6>信息服务不便捷</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye4.png" alt="" />
                  <h6>空间利用率低</h6>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
        <!-- 解决方案 -->
        <div class="container text-center">
          <div class="pro-tit d-none d-lg-block" style="font-size: 36px">
            解决方案
          </div>
          <div
            class="pro-tit d-block d-lg-none"
            style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
          >
            解决方案
          </div>
          <img
            style="width: 100%"
            src="@/assets/newSite/ibms/solution.png"
            alt=""
          />
        </div>
  
        <!-- 产品能力 -->
        <div class="pc-pro-power d-none d-lg-block">
          <div class="pro-tit" style="font-size: 36px">产品能力</div>
          <div>
            <div class="tabs container text-center">
              <div
                :class="['items', tbx == index ? 'active' : '']"
                v-for="(item, index) in tabListNew"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="item.img1" />
                <div class="tit">{{ item.tit }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="container text-center">
                <div class="row" v-show="tbx == 0">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        行政区划管理、机构管理、项目管理、楼栋管理、房屋管理
                    </div>
                    <div class="mcot">
                      <div>
                        支持维护业务基础数据，包括：行政区划、园区管理机构、项目、楼栋、房间等信息的维护；支持项目级系统参数配置；支持对角色进行项目级数据权限授权
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 1">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">工作台</div>
                    <div class="mcot">
                      <div>
                        展示房间即时的经营情况；支持对房间的预留；支持对外发布房间信息
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 2">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">公告管理、报事报修、设备管理、抄表管理</div>
                    <div class="mcot">
                      <div>
                        支持公告的管理和对外推送；支持对故障的提报和处理；支持仪表台账的维护；支持水电仪表的抄表日志维护以及仪表用量的统计
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 3">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit"> 合同管理、退租管理、大租户配置
                    </div>
                    <div class="mcot">
                      <div>
                        支持合同的创建、计费、审批；支持合同执行过程中的提前退租；支持对多房源租赁诉求的客户进行独立管理
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 4">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        合同费用、账单管理
                    </div>
                    <div class="mcot">
                      <div>
                        合同相关费用台账的管理；支持账单的生成、修改和下发  
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 5">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        驾驶舱
                    </div>
                    <div class="mcot">
                      <div>
                        提供丰富的指标，支持指标定制开发
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 6">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        客户管理
                    </div>
                    <div class="mcot">
                      <div>
                        支持客户预约、参观的过程跟进
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 7">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        推广页
                    </div>
                    <div class="mcot">
                      <div>
                        支持空置房源的对外展示
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 8">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        租赁情况明细表、应收未收明细表
                    </div>
                    <div class="mcot">
                      <div>
                        提供丰富的报表样式与数据分析能力，支持个性化报表的定制开发需求
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 9">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        收费规则配置、优管支付配置
                    </div>
                    <div class="mcot">
                      <div>
                        支持费项的收费规则自定义配置；支持商户的维护，支持费用的定向收款
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="mobile-pro-power d-block d-lg-none">
          <h4 class="pro-tit">产品能力</h4>
          <div class="">
            <div class="tabs">
              <div
                :class="['items', tbx == index ? 'active' : '']"
                v-for="(item, index) in tabListNew"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="item.img1" />
                <div class="tit">{{ item.tit }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="container text-center">
                <div class="row" v-show="tbx == 0">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">行政区划管理、机构管理、项目管理、楼栋管理、房屋管理</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>支持维护业务基础数据，包括：行政区划、园区管理机构、项目、楼栋、房间等信息的维护；支持项目级系统参数配置；支持对角色进行项目级数据权限授权</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 1">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">工作台</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>展示房间即时的经营情况；支持对房间的预留；支持对外发布房间信息</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 2">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">公告管理、报事报修、设备管理、抄表管理</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>支持公告的管理和对外推送；支持对故障的提报和处理；支持仪表台账的维护；支持水电仪表的抄表日志维护以及仪表用量的统计</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 3">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">合同管理、退租管理、大租户配置</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>支持合同的创建、计费、审批；支持合同执行过程中的提前退租；支持对多房源租赁诉求的客户进行独立管理</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 4">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">合同费用、账单管理</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>合同相关费用台账的管理；支持账单的生成、修改和下发</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 5">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">驾驶舱</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>提供丰富的指标，支持指标定制开发</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 6">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">客户管理</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>支持客户预约、参观的过程跟进</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 7">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">推广页</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>支持空置房源的对外展示</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 8">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">租赁情况明细表、应收未收明细表</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>提供丰富的报表样式与数据分析能力，支持个性化报表的定制开发需求</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 9">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                    <h4 style="text-align: left">收费规则配置、优管支付配置</h4>
                    <div
                      style="
                        text-align: left;
                        margin: 10px 0 10px 0;
                        color: #333333;
                      "
                    >
                      <div>支持费项的收费规则自定义配置；支持商户的维护，支持费用的定向收款</div>
                    </div>
                  </div>
                  <div
                  >
                    <img
                      src="@/assets/newSite/ibms/c.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- 产品优势 -->
        <div class="pc-scene d-none d-lg-block">
          <div class="pro-tit d-none d-lg-block">产品优势</div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad1.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad2.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad3.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad4.png" alt="" />
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
        <!-- 产品优势 -->
        <div class="mobile-scene d-block d-lg-none">
          <div
            class="pro-tit d-block d-lg-none"
            style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
          >
            产品优势
          </div>
          <div class="container text-center">
            <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad1.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad2.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad3.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/ibms/ad4.png" alt="" />
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
        <!-- 成功案列 -->
        <section
          class="pc-product d-none d-lg-block"
          style="margin: 80px 0 50px 0"
        >
          <div class="container text-center">
            <h1 class="pro-tit" style="margin-bottom: 20px; font-size: 36px">
              成功案例
            </h1>
            <div class="row align-items-start">
              <div
                :class="['col', idx == index ? 'acticv' : '']"
                @click="handleSwitch(index)"
                v-for="(item, index) in tabs"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="pro-wrap">
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 0"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap">
                  <h6 style="font-size: 35px">
                    {{caseA.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseA.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseA.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 1"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap" style="flex: 60%">
                  <h6  style="font-size:35px;">{{caseB.contentTitle}}</h6>
                  <p class="p-content">
                    {{caseB.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm" 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseB.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInRightBig"
              v-show="idx == 2"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div  class="wrap"  style="flex: 60%">
                  <h6  style="font-size:35px;">{{caseC.contentTitle}}</h6>
                  <p class="p-content">
                    {{caseC.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm" 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseC.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section
          class="mobile-product d-block d-lg-none"
          style="margin-top: 40px"
        >
          <div class="container text-center">
            <h3 class="pro-tit">成功案例</h3>
            <div class="row align-items-start">
              <div
                :class="['col', idx == index ? 'acticv' : '']"
                @click="handleSwitch(index)"
                v-for="(item, index) in tabs"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="pro-wrap">
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 0"
            >
              <div class="content">
                <div style="flex: 60%">
                  <h6 style="font-weight: 700; margin-bottom: 1rem">
                    {{caseA.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseA.contentDescription}}
                  </p>
                </div>
                <div style="text-align: center; margin-bottom: 20px">
                  <img :src="baseImgUrl + caseA.contentImgUrl"/>
                </div>
                <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 1"
            >
              <div class="content">
                <div style="flex: 60%">
                  <h6 style="font-weight: 700; margin-bottom: 1rem">
                    {{caseB.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseB.contentDescription}}
                  </p>
                </div>
                <div style="text-align: center; margin-bottom: 20px">
                  <img :src="baseImgUrl + caseB.contentImgUrl"/>
                </div>
                <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInRightBig"
              v-show="idx == 2"
            >
              <div class="content">
                <div style="flex: 60%">
                  <h6 style="font-weight: 700; margin-bottom: 1rem">
                    {{caseC.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseC.contentDescription}}
                  </p>
                </div>
                <div style="text-align: center; margin-bottom: 20px">
                  <img :src="baseImgUrl + caseC.contentImgUrl"/>
                </div>
                <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
              </div>
            </div>
          </div>
        </section>
  
        <right-nav-new class="d-none d-lg-block" />
      </div>
  
      <NewFooter @handleClickChild="handleClickChild" />
      <MobileFooter ref="mofooter" class="d-block d-lg-none" />
    </div>
  </template>
            
    <script>
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import SwiperCore, { Autoplay } from "swiper";
  SwiperCore.use([Autoplay]);
  import Qs from "qs";
import { Newslist } from "@/api/fetch";
  export default {
    name: "hardware",
    metaInfo() {
    return {
      title: '优制云智慧园区IBMS - 园区智能化管理中心',
      meta: [
        { name: 'keywords', content: '智慧园区,IBMS系统,园区管理,智能安防,能源管理,SaaS系统,工业互联网,优制云，伟班平台' },
        { name: 'description', content: '优制云工业互联网平台智慧园区IBMS系统，通过集成智能监控、能效优化、安全预警及综合服务调度等，实现园区资源整合与智能化运营，帮助企业降本增效，便捷管理。' },
        
      ],
    };
  },
    data() {
      return {
        tabs: ["润玉空间", "数研时空", "华瀚管道"],
        activeName: "1",
        idx: 0,
        tbx: 0,
        ids: -1,
        tabListNew: [
          {
            img1: require("@/assets/newSite/ibms/p1.png"),
            tit: "主数据管理",
          },
          {
            img1: require("@/assets/newSite/ibms/p2.png"),
            tit: "工作台",
          },
          {
            img1: require("@/assets/newSite/ibms/p3.png"),
            tit: "智慧运营",
          },
          {
            img1: require("@/assets/newSite/ibms/p4.png"),
            tit: "租赁管理",
          },
          {
            img1: require("@/assets/newSite/ibms/p5.png"),
            tit: "财务管理",
          },
          {
            img1: require("@/assets/newSite/ibms/p6.png"),
            tit: "驾驶舱",
          },
          {
            img1: require("@/assets/newSite/ibms/p7.png"),
            tit: "数字化招商",
          },
          {
            img1: require("@/assets/newSite/ibms/p8.png"),
            tit: "推广页",
          },
          {
            img1: require("@/assets/newSite/ibms/p9.png"),
            tit: "统计报表",
          },
          {
            img1: require("@/assets/newSite/ibms/p10.png"),
            tit: "财务配置",
          }
        ],
  
        swiperOption: {
          slidesPerView: 3,
          spaceBetween: 30,
          freeMode: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        baseImgUrl:'https://admin.veiban.com',
      caseList:[],
      caseA:{},
      caseB:{},
      caseC:{},
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    created() {
    this.getCase();
  },
    methods: {
      handleClickChild(){
     this.$refs.mofooter.handleDeal(2)
    },
    //成功案例
    getCase() {
      Newslist(
        Qs.stringify({ categoryId: '1839482945283117058', pageSize: 10, pageNo: 1,productCode:'ibms' })
      ).then((res) => {
        if (res.data.rows.length) {
            this.caseA = res.data.rows [0]
            this.caseB = res.data.rows [1]
            this.caseC = res.data.rows [2]
            this.caseList =  res.data.rows
        }
      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({path,id:'123456'});
      window.open(routeData.href, "_blank");
    },
      handleSwitch(idx) {
        this.idx = idx;
      },
      handleTest() {
        window.open("https://apipecloud.veiban.com/login");
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
  
      handleTab(index) {
        this.tbx = index;
      },
    },
    destroyed() {
      window.removeEventListener("scroll", this.onScroll, false);
    },
  };
  </script>
            
    <style lang="less" scoped>
  .apipe-cloud-course-container-madeSolution {
    .pc-banner {
      height: 600px;
      background-image: url("../../assets/newSite/ibms/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      .container {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .tit {
          font-size: 18px;
          margin: 4px 0;
        }
        .exp {
          margin-top: 2rem;
          font-size: 14px;
          border: 1px solid white;
          display: inline-block;
          width: 100px;
          padding: 2px 0;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .mobile-banner {
      height: 300px;
      width: 100%;
      background-image: url("../../assets/newSite/ibms/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .container {
        color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .title {
          font-size: 35px;
        text-align: left;
        color: white;
        font-family:Source Han Sans CN;
        font-weight:200;
        margin-bottom: 30px;
        }
        .titwrap{
        padding: 30px 20px;
        .tit {
          font-size: 13px;
          margin: 5px 0;
        }        
      }
        .exp {
          margin-top: 0.5rem;
          font-size: 12px;
          border: 1px solid white;
          display: inline-block;
          width: 80px;
          text-align: center;
        }
      }
    }
    .pc-industry {
      margin: 80px 0;
      .pro-tit {
        margin-bottom: 40px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          background: #f6faff;
          height: 240px;
          width: 300px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
      }
      img {
        width: 100px;
        height: 100px;
      }
      h6 {
        margin-top: 0.5rem;
        font-size: 16px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
      }
      .des {
        color: #333333;
        font-size: 14px;
      }
    }
    .mobile-industry {
      margin: 40px 0;
      .pro-tit {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #333;
      }
      .col {
        .p-3 {
          background: #f6faff;
          margin:10px ;
        }
      }
      img {
        // width: 100px;
        // height: 100px;
      }
      h6 {
        font-family: Source Han Sans CN;
      font-weight: 200;
      color: #333333;
      font-size: 15px;
      }
      .des {
        color: #333333;
        font-size: 14px;
      }
    }
    .pc-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
      }
      .tabs {
        display: flex;
        .items {
          flex: 25%;
          // height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          .tit {
            padding: 20px 0 16px 0;
            font-size: 18px;
            font-weight: 700;
          }
          &:hover {
            cursor: pointer;
          }
          img {
            width: 36px;
            height: 36px;
          }
        }
        .active {
          .tit {
            border-bottom: 4px solid rgba(20, 132, 255, 1);
          }
        }
      }
      .tab-cot {
        background: #f6faff;
        padding: 20px;
        .row {
          min-height: 300px;
          padding: 20px 100px;
          .mtit {
            text-align: left;
            font-size: 24px;
            font-weight: 500;
          }
          .mcot {
            text-align: left;
            margin: 20px 0 30px 0;
            color: #333333;
          }
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 120px;
              background: #1484ff;
              &:hover {
                background: #66b1ff;
              }
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    .mobile-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 20px;
        color: #333;
        font-weight: 700;
      }
      .tabs {
        display: flex;
        width: 375px;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 auto;
        .items {
          width: 33%;
          flex-shrink: 0;
          // height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          &:hover {
            cursor: pointer;
          }
          .tit {
            padding: 20px 0 16px 0;
          font-family: Source Han Sans CN;
          font-weight: 700;
          font-size: 16px;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        .active {
          .tit {
            border-bottom: 4px solid rgba(20, 132, 255, 1);
          }
        }
      }
      .tab-cot {
        background: #f6faff;
        padding: 20px 0;
        .row {
          min-height: 300px;
          padding: 10px;
          h4{
          font-family: Source Han Sans CN;
          font-weight: 700;
          color: #333333;
          font-size: 16px;
        }
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 100px;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    .pc-scene {
      margin: 80px 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          min-height: 200px;
          padding: 0 !important;
        }
      }
      img {
        width: 100%;
      }
      h6 {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
      }
    }
    .mobile-scene {
      margin: 0 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          padding: 5px !important;
        }
      }
      img {
        width: 100%;
      }
      h6 {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
      }
    }

    .pc-product {
      .acticv {
        span {
          height: 4px;
          border-bottom: 4px solid #1484ff;
          color: #1484ff;
        }
      }
      .col {
        cursor: pointer;
        span {
          display: inline-block;
          height: 40px;
        }
      }
      .pro-wrap {
        background: #f6faff;
        text-align: left;
        .container {
          display: flex;
  
          .cot {
            background: url("../../assets/newSite/home/pc.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: 702px;
            width: 100%;
          }
          img {
            // height: 16rem;
          }
          .content {
            margin: 40px 0;
            width: 100%;
            .wrap {
              flex: 60%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              p {
                font-family: Source Han Sans CN;
                font-weight: 200;
                color: #333333;
                width: 540px;
                margin-bottom: 20px;
                margin-top: 12px;
                font-size: 18px;
              }
            }
            .btn {
              width: 120px;
              background: #1484ff;
              height: 36px;
            }
          }
        }
      }
    }
    .mobile-product {
      .acticv {
        span {
          height: 4px;
          border-bottom: 4px solid #1484ff;
          color: #1484ff;
        }
      }
      .col {
        padding: 10px 0 0 0;
        cursor: pointer;
        span {
          display: inline-block;
          height: 40px;
          font-family: Source Han Sans CN;
          font-weight: 700;
          font-size: 16px;
        }
      }
      .pro-tit {
        font-family: Source Han Sans CN;
        font-weight: 700;
        color: #333333;
        font-size: 20px;
        margin-bottom: 15px;
      }
      .pro-wrap {
        text-align: left;
        background: #f6faff;
        .container {
          display: flex;
          .content {
            padding: 2rem 1.5rem 1.5rem 1.5rem;
            width: 100%;
            img {
              width: 100%;
              // height: 20rem;
            }
            .p-content {
              color: #666;
              font-size: 0.8rem;
              font-family: "思源黑体";
              line-height: 1.6rem;
            }
            .btn {
              width: 120px;
              background: #1484ff;
              color: white;
            }
          }
        }
      }
    }
  
   
  
   
  }
  </style>
            